import { create } from "zustand";
import { Api } from "../../../lib/api/axios";
import useAdminAuth from "../admin/useAdminAuth";
import { assertUnaryExpression } from "@babel/types";

const useMemberListing = create((set, get) => ({
  data: [],
  listMemberTotalRows: null,
  memberInfo: null,
  getData: async (
    searchTerm = "",
    page = 1,
    perPage = 10,
    sort = "",
    sortType = "",
    startDate = "",
    uplinerSearch="",
  ) => {
    try {
      const res = await Api.get(
        `/cms/list-member?handler=${
          useAdminAuth.getState().admin?.role === "master" ||
          useAdminAuth.getState().admin?.role === "admin"
            ? ""
            : useAdminAuth.getState().admin?._id
        }&searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}&start=${startDate}&uplinerSearch=${uplinerSearch}`
      );
      set(() => ({
        data: res.data?.docs,
        listMemberTotalRows: res.data?.totalDocs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getDataById: async (id) => {
    try {
      const res = await Api.get(`/cms/list-member/${id}`);
      set({
        memberInfo: res.data,
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  updateData: async (payload, controller) => {
    try {
      const res = await Api.patch("/cms/update-member", payload);
      // await get().getData(
      //   useAdminAuth.getState().admin?.role === 'master' ||
      //     useAdminAuth.getState().admin?.role === 'admin'
      //     ? ''
      //     : useAdminAuth.getState().admin?._id,
      //   '',
      //   1,
      //   10
      // );
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage,
        '',
        controller.sortType
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  resetPassword: async (payload) => {
    try {
      const res = await Api.patch("/cms/reset-password", payload);
      return res.status;
    } catch (error) {
      return error?.response?.data;
    }
  },
  createNew: async (payload, controller) => {
    try {
      const res = await Api.post("/cms/list-member", payload);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage,
        '',
        controller.sortType
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  updateMemberChatWidget: async (payload, id, controller) => {
    try {
      const res = await Api.patch(`/cms/list-member-chatwidget/${id}`, payload);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  createAnonymousMember: async (payload) => {
    try {
      const res = await Api.post(`/cms/list-member/create-anonymous`, payload);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  deleteMember: async (id, controller) => {
    try {
      const res = await Api.delete(`cms/list-member/${id}`);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage,
        '',
        controller.sortType
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getNewAccountNo: async () => {
    try {
      const payload = {
        type: "user",
      };
      const res = await Api.post("/cms/member/get-account-no", payload);
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getHierarchy: async (id) => {
    try {
      const path = `/cms/member/getHierarchy/${id}`;
      const res = await Api.get(path);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  resetMemberOrder: async (id, controller) => {
    try {
      const path = `cms/list-member/reset-order/${id}`;
      const res = await Api.post(path);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage,
        '',
        'desc',
        '',
        controller.uplinerSearch
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getAvailableBalance: async (id) => {
    try {
      const res = await Api.post(`cms/list-member/getAvailableBalance/${id}`);
      return res.data;
    }
    catch(error){
      return error?.response?.data;
    }
  }
}));

export default useMemberListing;
