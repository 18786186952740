import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const clsxm = (...classes) => twMerge(clsx(...classes));

export const  convertStringToArray = (str, delimiter='|') => {
  if(!str.includes(delimiter)){
    return [Number(str)];
  }
  return str.split(delimiter).map(Number);
}

export const checkNumberInArray = (str, decimal = 2, delimiter='|') => {
  if (!str.includes(delimiter)){
    let formattedStr = parseFloat(str).toFixed(decimal);
    return formattedStr;
  }
  else{
    let parts = str.split(delimiter);

    // Map over each part, convert to a number, format to one decimal place, and join them back together
      let formattedStr = parts.map(part => parseFloat(part).toFixed(decimal)).join(delimiter);
    
      return formattedStr; // Output: "0.10|0.25|0.25"
  }
}