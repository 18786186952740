import React from "react";
import { Text } from "components";
import { currency, toUSD } from "lib/currencyFormatter";

export default function GainSection({ userInfo }) {
  return (
    <div className="container mt-10 ">
      <div className="w-full flex flex-row justify-center">
        <Text className="text-[1rem] font-extrabold"> GAIN </Text>
      </div>
      <div className="w-full flex justify-center bg-pu">
        <div className="flex flex-row w-[90%] flex-wrap">
          <div className="flex  w-full justify-center p-3">
            <div className="rounded-lg bg-purple shadow-lg  text-center h-[5rem] p-5 w-full">
              <Text className="!font-bold pb-1">
                {toUSD(userInfo?.user?.profitLoss)}
              </Text>
              <Text className="text-blue-800 text-[0.8rem]">Gain</Text>
            </div>
          </div>
          <div className="flex w-full justify-center p-3">
            <div className="rounded-lg bg-purple shadow-lg  text-center h-[5rem] p-5 w-full">
              <Text className="!font-bold pb-1">
                {toUSD(userInfo?.user?.availableBalance)}
              </Text>
              <Text className="text-blue-800 text-[0.8rem]">
                Available Balance
              </Text>
            </div>
          </div>
          <div className="flex w-full justify-center p-3">
            <div className="rounded-lg bg-purple shadow-lg  text-center h-[5rem] p-5 w-full">
              <Text className="!font-bold pb-1">
                {toUSD(userInfo.user?.todayCommission)}
              </Text>
              <Text className="text-blue-800 text-[0.8rem]">
                Today Commission
              </Text>
            </div>
          </div>
          <div className="flex w-full justify-center p-3">
            <div className="rounded-lg bg-purple shadow-lg  text-center h-[5rem] p-5 w-full">
              <Text className="!font-bold pb-1">
                {userInfo?.user?.creditScore ?? 100}
              </Text>
              <Text className="text-blue-800 text-[0.8rem]">Credit Score </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
