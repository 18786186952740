import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';

const useCommissionGroupStore = create((set, get) => ({
  data: [],
  totalRows: 0,
  getData: async (searchTerm = '', page = 1, perPage = 10, sort = '', sortType = '') =>{
    try{
      const path = `/cms/commission-group/getAllMultilevelGroup?searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}`;
      const res = await Api.get(path);
      set(()=>({
        data: res?.data?.docs,
        totalRows: res?.data?.totalDocs
      }));
      return res.data;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  getDataById: async(id) => {
    try{
      const res = await Api.post(`/cms//commission-group/getCommissionGroupById/${id}`);
      return res.data;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  createData: async(payload, controller) => {
    try{
      const path = `/cms/commission-group/createMultiLevelRateGroup`;
      const res = await Api.post(path, payload);
      get().getData(controller.search, controller.page, controller.perPage);
      return res;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  updateData: async(id, payload, controller) =>{
    try{
      const path = `/cms/commission-group/updateMultiLevelRateGroup/${id}`;
      const res = await Api.post(path, payload);
      get().getData(controller.search, controller.page, controller.perPage);
      return res;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  deleteData: async(id, controller) => {
    try{
      const path = `/cms/commission-group/deleteMultiLevelRateGroup/${id}`;
      const res = await Api.delete(path);
      get().getData(controller.search, controller.page, controller.perPage);
      return res;
    }
    catch(error){
      return error?.response?.data;
    }
  }
}))

export default useCommissionGroupStore;