import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import HeaderForm from "../HeaderForm";

export default function Layout({
  children,
  isWithHeader = "true",
  isWithFooter = "true",
  isHeaderForm = "false",
  bgColor = "bg-white",
  formTitle = "Form",
  className = "" }) {
  return (
    <>
      <div className={`h-auto w-full relative z-[1] min-h-[100vh] pb-[80px] overflow-y-auto ${bgColor} `}>
        {(isWithHeader === "true") && (<Header />)}
        {(isHeaderForm === "true") && (<HeaderForm formTitle={formTitle} className={className} />)}
        {children}
        {(isWithFooter === "true") && (<Footer />)}
      </div>

    </>
  )
}